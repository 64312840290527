<template>
    <div class="center m-5">
        <div class="m-4">
            <div class="clearfix">
                <img src="/media/images/logo.jpg" alt="" class="float-start">
                <div class="float-end">
                    <div class="text-danger fs-2">Quote</div>
                    <div>Quote time: {{data.created_at}} PST</div>
                </div>
            </div>
        </div>

        <spinner v-if="loading" />

        <div v-if="error" class="alert alert-danger">{{error}}</div>
        <div v-else class="mb-5">
            <div class="m-4">
                <div class="row">
                    <div class="col-md-6">
                        <div><span class="fw-bold me-1">Quote ID:</span> {{data.id}}</div>
                        <div><span class="fw-bold me-1">Status:</span> {{data.status}}</div>
                        <div><span class="fw-bold me-1">Quoted Date:</span> {{data.created_at}}</div>
                        <div><span class="fw-bold me-1">Valid Until:</span> 
                            <span v-if="data.valid_until">{{data.valid_until}}</span>
                            <span v-else>N/A</span>
                        </div>
                        <div><span class="fw-bold">Ma Labs Sales Rep:</span> {{data.sales}}</div>
                        <div><div class="float-start col-md-3"><span class="fw-bold">Ma Labs Sales Rep Note:</span></div>
                            <div class="float-start col-md-9">{{data.sales_comments}}</div> </div>
                    </div>
                    <div class="col-md-6">
                        <div><span class="fw-bold me-1">Customer ID:</span> {{data.custno}}</div>
                        <div><span class="fw-bold me-1">Customer Company:</span> {{data.company}}</div>
                        <div><span class="fw-bold me-1">Customer Email:</span> {{data.email}}</div>
                        <div><span class="fw-bold me-1">Customer Contact:</span> {{data.contact}}</div>
                        <div><span class="fw-bold me-1">Customer Phone:</span> {{data.phone}}</div>
                    </div>
                </div>
                
            </div>    

            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <td width="10%" class="fw-bold ps-4">Item No.</td>
                            <td width="32%" class="fw-bold">Product Description</td>
                            <td width="10%" class="fw-bold">Branch</td>
                            <td width="8%" align="right" class="fw-bold pe-2">Quantity</td>
                            <td width="10%" align="right" class="fw-bold pe-2">Quoted Price</td>
                            <td width="10%" align="right" class="fw-bold pe-4">Subtotal</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in data.quote_items">
                            <td class="ps-4">{{item.itmno}}</td>
                            <td>
                                <div>{{item.desc}}</div>
                                <div class="mt-4">
                                    <div class="mt-2 mb-2"><i class="bi bi-wrench-adjustable"></i> <u>Configuration Components</u>: </div> 
                                    <table class="table table-condensed table-striped">
                                        <tbody>
                                        <tr v-for="cp in item.system_details">
                                            <td width="20%" valign="top" class="pe-2">{{cp.itmno}}</td>
                                            <td width="80%">{{cp.desc}} ({{cp.qty}} pcs)  </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </td>
                            <td>{{branchName(item.branch)}}</td>
                            <td align="right" class="pe-2">{{item.quantity}}</td>
                            
                            <td align="right" class="pe-2">
                                 <span v-if="(data.status == 'Approved' || data.status == 'Converted') && item.quoted_price != null">{{formatPrice(item.quoted_price)}}</span>
                                <span v-else>N/A</span>
                            </td>
                            <td align="right" class="pe-4">
                                <span v-if="(data.status == 'Approved' || data.status == 'Converted') && item.quoted_price != null">{{formatPrice(getSubtotal(item.quoted_price, item.quantity))}}</span>
                                <span v-else>N/A</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="mb-5 me-4">
                    <table class="col-md-12">
                    <tr>
                        <td class="text-end fw-bold mr-2$">Total Quoted Price:</td>
                        <td width="10%" align="right">
                        <span v-if="(data.status == 'Approved' || data.status == 'Converted') && data.total_quoted_price">{{(formatPrice(data.total_quoted_price))}}</span>
                        <span v-else>N/A</span>
                        </td>
                    </tr>
                    </table>

                </div>
            </div>
            
            <div class="m-4">
                <div>
                    <div><span class="fw-bold">Company Address: </span> 

                            <span v-if="data.company">{{data.company}}, </span>

                        <span v-if="data.addr1">{{data.addr1}},&nbsp;</span>
                        <span v-if="data.addr2">{{data.addr2}}, &nbsp;</span>
                        <span>{{data.city}}, {{data.state}} {{data.zipcode}}, &nbsp;</span>
                        <span>{{data.country}}</span>
                        <span v-if="data.bphone">, Phone: {{data.bphone}}</span>
                    
                    </div>
                    <div><span class="fw-bold">Shipping Address: </span> 

                            <span v-if="data.scompany">{{data.scompany}}, </span>

                        <span v-if="data.saddr1">{{data.saddr1}},&nbsp;</span>
                        <span v-if="data.saddr2">{{data.saddr2}}, &nbsp;</span>
                        <span>{{data.scity}}, {{data.sstate}} {{data.szipcode}}, &nbsp;</span>
                        <span>{{data.scountry}}</span>
                        <span v-if="data.sphone">, Phone: {{data.sphone}}</span>
                    
                    </div>
                    <div><span class="fw-bold">Delivery Preference:</span> {{data.shipcode}}</div>
                    <div><div class="fw-bold">Special Instruction:</div> 
                        <div>{{data.customer_comments}} </div> 
                    </div>
                    
                </div>
            </div>    

            
        </div>

        <div class="m-4">
            <p>
                <div><u>Terms & Conditions</u></div>
                <div>The Terms and Conditions of Sale at www.malabs.com/terms-conditions apply to all transactions.</div>
            </p>
            <p>
                <div><u>Price and Availability</u></div>
                <div>Please check with your account manager for final pricing and product availability (subject to change without prior notice). Unless otherwise indicated, all price quotes are based on a cash discount offered to all buyers for payment by cash or check as permitted by law. Some Brands may not be eligible for sale outside the U.S. Please review with your account manager. All logistics charges, fees, costs, and taxes are subject to change based on final destination.</div>
            </p>
            <p>
                <div><u>Destination Control Statement</u></div>
                <div>Diversion contrary to US export laws of goods or services purchased from Ma Labs is strictly prohibited.</div>
            </p>

            <p class="mt-5">Copyright © {{year}} Ma Laboratories, Inc. All Rights Reserved.</p>
        </div>


        
    </div>
</template>

<script>
import Spinner from "../../components/Spinner.vue"
import { ref } from 'vue'
import { getAuthAPI } from "../../utils/axios-api"
import { formatPrice, branchName } from '../../utils/common'

export default {
    name: "QuoteRequestPrint",
    components: { Spinner },
    props: ["id"],
    setup(props) {
        const loading = ref(true)
        const error = ref("")
        const message = ref("")
        const data = ref("")

        //get quote list
        getAuthAPI.get('/order/quote-request/details/' + props.id)
        .then(response => {
            data.value = response.data
            
            loading.value = false
        })
        .catch(err => {
        try {
            error.value = err.message
        }
        catch {
            error.value = "Something is wrong."
        }
        loading.value = false
        })
        

        function getSubtotal(price, qty) {
        return price*qty
        }

        return {
            loading,
            error,
            message,
            data,
            formatPrice,
            branchName,
            getSubtotal
            }
    }
}
</script>

<style>

</style>